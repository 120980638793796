import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Movies App',
        link: 'moviesapp-65965.firebaseapp.com',
        github: 'github.com/gospel02/MoviesApp',
        id: 1,
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Spades E-commerce',
        link: 'spades-ecommerce-site.netlify.app',
        github: 'github.com/Keone2399/e-commerse',
        id: 2,
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Golf Project',
        link: 'keone2399.github.io/golfProject/',
        github: 'github.com/Keone2399/golfProject',
        id: 3,
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Rockey Burgers',
        link: 'keone2399.github.io/Burgers/',
        github: 'github.com/Keone2399/burgers',
        id: 4,
    },
    {
        image: 'image-5',
        category: 'Development',
        title: 'Todo App',
        link: 'keone2399.github.io/TodoApp/',
        github: 'github.com/Keone2399/TodoApp',
        id: 5,
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={`https://${value.link}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn mr-1" href={`https://${value.link}`}>Visit Site</a>
                                        <a className="rn-btn ml-1" href={`http://${value.github}`}>View Code</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;
