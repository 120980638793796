import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let
        tab1 = "Main skills",
        tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>

                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Front End <span> - UI/UX</span></a>
                                                    Uses HTML, CSS, and JS(ES6) to build Responsive and Delightful websites.
                                                </li>
                                                <li>
                                                    <a href="/service">Back End <span> - Development</span></a>
                                                    Experienced in back end development using Typescript, Node, C# , and ASP.NET. With Databases such as MySQL, MangoDB, and Firebase.
                                                </li>
                                                <li>
                                                    <a href="/service">Frameworks <span> - Functionality</span></a>
                                                    Skilled in using React, Angular, Bootstrap, and Material Design as frameworks for powerful applications.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Software Developer<span> - Namify LLC.</span></a> 2019 - 2019
                                               </li>
                                               <li>
                                                   <a href="/service">Customer Service / Sales<span> - The Home Depot</span></a> 2018 - Current
                                               </li>

                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Certificate in Web Development<span> - Mountainland Technical College, Lehi, UT</span></a> 2018
                                               </li>
                                               <li>
                                                   <a href="/service">High School Diploma | Technology<span> - Utah County Academy of Sciences</span></a> 2017
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;
